import React, { useRef } from "react";
import { Autocomplete, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Styles from "./styles";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import NorthWestIcon from '@mui/icons-material/NorthWest';
import searchPic from '../../assets/CommonIcons/search.png'
import lodash from 'lodash';

export default function SearchField({
  placeholder,
  handleChange,
  options = [],
  setValue,
  value,
  handleClear,
  categories
}) {
  const classes = Styles();
  const textFieldRef = useRef(null);

  const handleInputChange = (evt, newValue) => {
    if (evt?.type === "click") {
      handleChange(newValue);
      textFieldRef.current.blur();
    } else if (evt?.key !== "Enter") {
      setValue(newValue);
    } else if (evt?.key === "Enter") {
      setValue(newValue);
      handleChange(newValue);
      textFieldRef.current.blur();
    }
  };

  const handleKeyDownFunc = (event) => {
    if (event.key === "Enter") {
      handleChange(value);
      textFieldRef.current.blur();
    }
  };
  // Filter products based on search query
  const filteredProducts = options.filter(product =>
    product.label.toLowerCase().includes(value.toLowerCase())
  );

  // Extract keywords from filtered products
  const extractedKeywords = filteredProducts.reduce((keywords, product) => {
    const productKeywords = [product.category_name]; // Split title into words
    return [...keywords, ...productKeywords];
  }, []);

  // Remove duplicate keywords
  const uniqueKeywords = [...new Set(extractedKeywords)];
  const keywordOptions = categories && Array.isArray(categories) && categories.length > 0 && categories.filter(_ => uniqueKeywords.includes(_.category_name)).map(c => Object.assign({}, c, { label: c.category_name, viewType: 'keyword' }));

  return (
    <Autocomplete
      className={classes.inputWrapper}
      freeSolo
      options={keywordOptions && Array.isArray(keywordOptions) && keywordOptions.length > 0 ? lodash.uniqBy(keywordOptions, 'category_name').concat(options) : options}
      onInputChange={handleInputChange}
      onClear={handleClear}
      includeInputInList
      filterOptions={x => x}
      value={value || ""}
      renderOption={(opt, opt2) => opt2.viewType && opt2.viewType === 'keyword' ? <div className={classes.keywordTitle} onClick={() => handleChange(opt2.category_name)}><div><SearchIcon style={{ color: "#B7B7B7", fontSize: '18px', marginRight: '5px' }} /> {opt2.category_name}</div><NorthWestIcon style={{ color: "#B7B7B7", fontSize: '18px' }} /></div> : <div className={classes.searchGrid}>
        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleChange(opt2.id)}>
          {opt2 && opt2.product_video ? <video
            src={opt2.product_video}
            type="video/mp4"
            controlsList="nodownload"
            style={{ objectFit: 'content' }}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
            width="30"
            height="30"
          /> : opt2 && opt2.product_gallery ?
            <img
              src={opt2.product_gallery && opt2.product_gallery.split(',') && opt2.product_gallery.split(',')[0] || ''}
              alt="test"
              width="30"
              height="30"
              style={{ objectFit: 'content' }} /> : null}
          <div className={classes.productTitle}>{opt2.label}</div>
        </div>
        <Link to={`/search?category=${opt2.post_categories_id}`} className={classes.category_name}>in {opt2.category_name}</Link>
      </div>}
      ListboxProps={{
        sx: {
          fontFamily: `var(--font)`,
        },
        className: classes.bodyScroll,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          type="search"
          placeholder={placeholder}

          // inputProps = {
          //   {
          //     sx: {
          //       '&::placeholder': {
          //         color: 'red',
          //         opacity: 1, // otherwise firefox shows a lighter color
          //       },
          //     },
          //   }
          // }
          className={classes.fieldColor}
          onKeyDown={handleKeyDownFunc}
          variant="standard"
          size="small"
          inputRef={textFieldRef}
          sx={{
            input: {
              border: "none",
              color: "black",
              fontSize: "14px",
              "&::placeholder": {    // <----- Add this.
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
            style: { color: "black" },
            autoComplete: "new-password",
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <div className={`${classes.searchIcon} noSelect`}>
                  {(value && (
                    <Close
                      className={classes.closeIcon}
                      onClick={handleClear}
                    />
                  )) ||
                    null}
                  {/* <SearchIcon
                    onClick={() => {
                      if (value) {
                        handleChange(value);
                        textFieldRef.current.blur();
                      }
                    }}
                  /> */}
                  <img src={searchPic} style={{ width: "20px" }} alt="search" onClick={() => {
                    if (value) {
                      handleChange(value);
                      textFieldRef.current.blur();
                    }
                  }} />
                </div>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
