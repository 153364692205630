import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "40px",
  },
  root: {
    boxSizing: "border-box",
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "21px",
    lineHeight: "25px",
    color: "#000000",
    marginBottom: "20px",
  },
  fieldColor: {
    width: "100%",
    "& :after": {
      borderBottomColor: "#2ca01c",
    },
    "& :before": {
      borderBottomColor: "#2ca01c",
    },
    color: "green !important",
    "& label.Mui-focused": {
      color: "#2ca01c",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2ca01c",
    },
  },
  textSize: {
    fontSize: "14px",
  },
  error: {
    fontSize: "14px",
    color: "red",
  },
  subscribeBtn: {
    height: "40px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#FFFFFF",
    background: theme.palette.accentColor.main,
    textAlign: "center",
    textTransform: "capitalize",
    marginTop: "10px !important",
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px !important",
      marginTop: "0 !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0 !important",
      marginTop: "10px !important",
    },
  },
}));

export default useStyles;
