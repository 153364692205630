import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import Styles from "./styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Footer from "components/Footer";
import { Grid } from "@mui/material";

import whatsappIcon from '../../assets/socialIcons/social.png';
import Draggable from 'react-draggable'; // The default
import { useRef } from "react";

function FooterBtn() {
    const classes = Styles();

    const [startX, setStartX] = useState(null);
    const [startY, setStartY] = useState(null);
    const [isDragging, setIsDragging] = useState(false);


    const eventControl = (event) => {
        if (event.type === 'mousemove' || event.type === 'touchmove') {
            setIsDragging(true)
        }

        if (event.type === 'mouseup' || event.type === 'touchend') {
            setTimeout(() => {
                setIsDragging(false);
            }, 100);

        }
    }

    const handleTouchStart = event => {
        const touch = event.touches[0];
        setStartX(touch.clientX);
        setStartY(touch.clientY);
    };

    const handleTouchEnd = event => {
        const touch = event.changedTouches[0];
        const endX = touch.clientX;
        const endY = touch.clientY;
        const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
        if (distance < 5) {
            console.log('Click!');
            window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
        }
    };

    return (
        <Draggable handle=".handle" bounds="body" onDrag={eventControl} onStop={eventControl}>
            <div className="handle" style={{
                position: "fixed",
                bottom: "75px",
                right: "35px",
                zIndex: "999",
            }}>
                <img draggable={false} src={whatsappIcon} width={50} height={50} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
                    // onTouchEnd={(e) => {
                    //     e.preventDefault();
                    //     if (!isDragging) {
                    //         console.log('touch end was work now');
                    //         console.log('navigate to whatsapp on mobile group');
                    //         window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
                    //     }
                    // }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (!isDragging) {
                            console.log('navigate to whatsapp group');
                            window.open('https://chat.whatsapp.com/J1h0DVZVT605eurcdt0RSk', '_blank')
                        }
                    }} />
            </div>
        </Draggable>
    );
}

export default FooterBtn;
