/**
 *
 * Modal Location
 *
 */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  Fade,
  Paper,
  Button,
  TextField,
  Autocomplete,
  Typography,
  Box,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Styles from "./styles";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { geoLocation as geoLocationAction } from "store/session/actions";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ButtonSpinner from "components/ButtonSpinner";
import parse from "autosuggest-highlight/parse";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { geocodeByLatLng } from "react-google-places-autocomplete";
import { Toaster, toast } from "react-hot-toast";

const libraries = ["places"];
/**
 *
 * @param {object} props
 * @returns
 */
function ModalLocation(props) {
  const {
    show,
    onClose,
    paperClassName,
    children,
    dispatch,
    geoLocation,
    user,
  } = props;
  const classes = Styles();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `AIzaSyDt1WML79vgd-irSGt1EO__9QtFyPqQPGo`,
    libraries,
  });

  const [showModal, setModalOpen] = useState(false);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const closeModal = () => {
    setModalOpen(false);
    if (onClose) onClose();
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Grid container>
      {children && children(() => setModalOpen(!showModal))}
      <Modal
        open={showModal || show || false}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
        closeAfterTransition
      >
        <Fade in={showModal || show || false}>
          <Paper
            className={`${classes.paper} ${paperClassName}`}
            style={
              !md ? { width: "95%", minHeight: "80px" } : { width: "60vw" }
            }
          >
            <Grid item className={classes.closeContainer}>
              <CloseIcon onClick={closeModal} className={classes.closeIcon} />
            </Grid>
            <Grid
              container
              position="relative"
              alignItems="center"
              className={classes.container}
              wrap="nowrap"
            >
              <Map
                dispatch={dispatch}
                geoLocation={geoLocation}
                closeModal={closeModal}
                classes={classes}
                user={user}
              />
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </Grid>
  );
}

export default ModalLocation;

function Map({ dispatch, geoLocation, closeModal, classes }) {
  const {
    value,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const defaultLocation = { lat: 25.2705654, lng: 55.3652853 };
  const [selected, setSelected] = useState(false);
  const [locationDetails, setLocationDetails] = useState(false);
  const [kiloMeter, setKiloMeter] = useState(false);
  const [search, setSearch] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(16);
  const [btnLoader, setBtnLoader] = useState(false);
  const [clearBtnLoader, setClearBtnLoader] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const address = geoLocation?.locationName || null;
  const radius =
    (geoLocation?.kiloMeter && parseInt(geoLocation?.kiloMeter)) || null;

  useEffect(() => {
    if (address) {
      setValue(address);
    }

    if (radius) {
      setKiloMeter(radius);
    }

    if (!locationDetails && geoLocation?.coordinates) {
      setLocationDetails(
        Object.assign(
          {},
          {
            coordinates: geoLocation?.coordinates,
            locationName: geoLocation?.locationName,
          },
        ),
      );
    }
  }, []);

  const locateMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          geocodeByLatLng({ lat: latitude, lng: longitude })
            .then((results) => {
              setValue(results[0].formatted_address, false);
              setLocationDetails(
                Object.assign(
                  {},
                  {
                    coordinates: { lat: latitude, lng: longitude },
                    locationName: results[0].formatted_address,
                  },
                ),
              );
              setSearch(true);
              setSelected({ lat: latitude, lng: longitude });
              setZoomLevel(16);
              setShowConfirm(true);
            })
            .catch((error) => console.error(error));
        },
        (error) => {
          toast.error(`${error.message}`, { position: "bottom-center" });
        },
      );
    } else {
      toast.error(`Geolocation is not supported by this browser.`, {
        position: "bottom-center",
      });
    }
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const locationName = results?.[0]?.formatted_address;

    const { lat, lng } = await getLatLng(results[0]);
    setLocationDetails(
      Object.assign({}, { coordinates: { lat, lng }, locationName }),
    );

    setSearch(true);
    setSelected({ lat, lng });
    setZoomLevel(16);
    setShowConfirm(true);
  };

  const confirmLocation = () => {
    setSearch(false);
    setBtnLoader(true);
    dispatch(
      geoLocationAction(
        Object.assign({}, { ...locationDetails }, { kiloMeter }),
        closeModal,
        setBtnLoader,
      ),
    );
  };

  const handleSearch = () => {
    console.log('search was therer');

    setSearch(true);
    setSelected(locationDetails?.coordinates);
    setZoomLevel(60);
  };

  const handleClearLocation = () => {
    setSearch(false);
    setLocationDetails(false);
    setClearBtnLoader(true);
    dispatch(
      geoLocationAction(
        Object.assign(
          {},
          {
            coordinates: { lat: null, lng: null },
            locationName: null,
            kiloMeter: null,
            type: "clearLocation",
          },
        ),
        closeModal,
        setClearBtnLoader,
        {
          setValue,
          setKiloMeter,
        },
      ),
    );
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "80vh",
        }}
      >
        <GoogleMap
          zoom={zoomLevel}
          onLoad={(mapData) => {
            mapData.setOptions({
              styles: [
                {
                  featureType: "poi.business",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "transit",
                  elementType: "labels.icon",
                  stylers: [{ visibility: "off" }],
                },
              ],
            });
          }}
          center={
            search
              ? selected
              : geoLocation?.coordinates?.lat && geoLocation?.coordinates?.lng
                ? geoLocation?.coordinates
                : defaultLocation
          }
          mapContainerClassName="map-container"
          options={{
            zoomControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
        >
          <Marker
            position={
              search
                ? selected
                : geoLocation?.coordinates?.lat && geoLocation?.coordinates?.lng
                  ? geoLocation?.coordinates
                  : defaultLocation
            }
          />
        </GoogleMap>
      </Box>

      <Box
        sx={{
          background: "white",
          position: "absolute",
          top: "25px",
          borderRadius: "5px",
          boxShadow: "0px 21px 34px 0px #00000040",
          width: sm ? "97%" : "90%",
          opacity: ".9",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "10px",
            gap: "10px",
            maxHeight: "200px",
          }}
        >
          <Grid container xs={12}>
            <Typography className={classes.locationTitle}>
              Choose your address
            </Typography>
            <Grid item xs={12} style={{ display: "flex", gap: 10 }}>
              <Grid item xs={10}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  value={value}
                  disabled={clearBtnLoader}
                  options={data}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.description
                  }
                  filterOptions={(x) => x}
                  onInputChange={(e, newInputValue) => {
                    if (e?.type === "click") {
                      handleSelect(newInputValue);
                    }
                    if (newInputValue === "") {
                      setShowConfirm(false);
                    }
                  }}
                  ListboxProps={{
                    sx: {
                      fontSize: "12px",
                      fontFamily: `var(--font)`,
                    },
                  }}
                  renderOption={(props, option) => {
                    if (option) {
                      const matches =
                        option.structured_formatting
                          .main_text_matched_substrings || [];

                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [
                          match.offset,
                          match.offset + match.length,
                        ]),
                      );

                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid
                              item
                              sx={{
                                width: "calc(100% - 44px)",
                                wordWrap: "break-word",
                              }}
                            >
                              {parts.map((part, index) => (
                                <Box
                                  key={index}
                                  onClick={(e) => {
                                    return false;
                                  }}
                                  component="span"
                                  sx={{
                                    fontWeight: part.highlight
                                      ? "bold"
                                      : "regular",
                                  }}
                                >
                                  {part.text}
                                </Box>
                              ))}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {option.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <div className={classes.inputContainer}>
                      <TextField
                        {...params}
                        placeholder="Search your location..."
                        fullWidth
                        variant="standard"
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          type: "text",
                          endAdornment: !btnLoader && (
                            <InputAdornment
                              position="end"
                              onClick={handleSearch}
                            >
                              <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <div className={classes.inputContainer}>
                  <TextField
                    placeholder="KM"
                    variant="standard"
                    type="number"
                    value={kiloMeter || ""}
                    disabled={btnLoader || clearBtnLoader}
                    onChange={(e) => {
                      setKiloMeter(e.target.value);
                      if (address && value) {
                        setShowConfirm(true);
                      }
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.footerContainer}>
              <Button
                variant="contained"
                color="accentColor"
                className={classes.confirmBtn}
                disabled={clearBtnLoader ? true : showConfirm ? false : true}
                onClick={confirmLocation}
                style={{ textTransform: "none" }}
              >
                {btnLoader ? <ButtonSpinner size={20} /> : "Confirm location"}
              </Button>
              <Button
                variant="contained"
                color="cancelBtn"
                className={classes.cancelBtn}
                disabled={btnLoader || clearBtnLoader}
                style={{ textTransform: "none" }}
                onClick={() => {
                  closeModal();
                  setSearch(false);
                }}
              >
                Cancel
              </Button>
              {value && address ? (
                <Button
                  variant="contained"
                  color="cancelBtn"
                  className={classes.cancelBtn}
                  disabled={btnLoader || !address}
                  onClick={() => handleClearLocation()}
                  style={{ textTransform: "none" }}
                >
                  {clearBtnLoader ? <ButtonSpinner size={20} /> : "Clear"}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid
        sx={{
          position: "absolute",
          bottom: "20px",
          right: "10px",
        }}
      >
        <button
          style={{
            background: "white",
            borderRadius: "50px",
            boxShadow: "0px 21px 34px 0px #00000040",
            opacity: ".9",
            border: "1px solid white",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px",
            cursor: "pointer",
            gap: "4px",
          }}
          onClick={locateMe}
        >
          Locate me <MyLocationIcon />
        </button>
      </Grid>
      <Toaster />
    </>
  );
}
