import axios from "axios";

let apiInstance = {
  baseURL: global.config.API_URL || "https://dealpopupservice.ahsan.cloud/",
  timeout: 100000,
  headers: { Accept: "application/json" },
}

console.log("API INSTANCE ", apiInstance);

const api = axios.create(apiInstance);

export function setAuthToken(authToken) {
  api.defaults.headers.common["Authorization"] = authToken;
}

export function removeAuthToken() {
  delete api.defaults.headers.common["Authorization"];
}

export default api;
