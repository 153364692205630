/**
 *
 *  File Upload
 *
 */

import React, { useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import styles from "./styles";
import { Grid, Typography } from "@mui/material";
import Error from "components/Error";
import remotes from "store/session/remotes";
import { getContentType } from "./utils";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import api from "helpers/api";

function FileUpload({
  input,
  contentType = "*",
  label,
  upload,
  multiple,
  max,
  secret,
  meta: { touched, error, warning },
  errorType,
  uploadApi,
  uploadUserProfile,
  customStyle,
}) {
  const [uploadFiles, setuploadFiles] = useState(false);
  const [uploadError, setuploadError] = useState(false);
  const [uploadPercentage, setPercentage] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(1);
  const [totalFiles, setTotalFiles] = useState(false);

  const inputRef = useRef(false);
  const labelValue = label;
  const accept = contentType;

  const onUpload = async (files, index) => {
    console.log('this function called first');
    if (!files || files.length <= index) {
      return false;
    }

    try {
      let file = files[0]
      let newFileName = 'USR' + '.' + file.name.split('?')[0].split('.').pop();
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      let formData = new FormData();
      formData.append('files', file, newFileName);
      let response = await api.post('/image/single/upload', formData, config);

      if (response.status === 200) {
        setPercentage(false);
        input.onChange(global.config.API_URL + response.data.imageUrl);
        uploadUserProfile(global.config.API_URL + response.data.imageUrl);
        inputRef.current = false;
        setUploadedFile(1);
        setPercentage(false);
        setTotalFiles(false);
        // props.onChange({ target: { value: global.config.API_URL + response.data.imageUrl } })
      }
    }
    catch (err) {
      console.log(err);
    }

    // const ajax = new XMLHttpRequest();
    // const Upload = remotes.uploadUserProfile;
    // const fileName = files[index].name;
    // const fileContentType = getContentType(fileName);

    // Upload(
    //   Object.assign({}, { file_name: fileName, content_type: fileContentType }),
    // )
    //   .then((result) => {
    //     const { uploadURL, public_url, s3_file_key } = result;
    //     if (uploadURL) {
    //       ajax.upload.addEventListener(
    //         "progress",
    //         (e) => setPercentage(Math.round((e.loaded / e.total) * 100)),
    //         false,
    //       );
    //       ajax.addEventListener(
    //         "load",
    //         () => {
    //           const inputVal = secret
    //             ? Object.assign({}, { key: s3_file_key, file_name: fileName })
    //             : public_url;
    //           if (index === 0) {
    //             inputRef.current = [inputVal];
    //           } else {
    //             inputRef.current.push(inputVal);
    //           }
    //           if (files.length - 1 === index) {
    //             if (uploadApi) {
    //               uploadUserProfile(inputRef.current[0]);
    //             }
    //             input.onChange(inputRef.current[0]);

    //             inputRef.current = false;
    //             setUploadedFile(1);
    //             setPercentage(false);
    //             setTotalFiles(false);
    //           } else {
    //             setUploadedFile(index + 2);
    //             setPercentage(false);
    //             onUpload(files, index + 1);
    //           }
    //         },
    //         false,
    //       );
    //       ajax.addEventListener(
    //         "error",
    //         () => setuploadError("Upload Failed"),
    //         false,
    //       );
    //       ajax.addEventListener(
    //         "abort",
    //         () => setuploadError("Upload Failed"),
    //         false,
    //       );
    //       ajax.open("PUT", uploadURL, true);
    //       ajax.setRequestHeader("Content-Type", fileContentType);
    //       ajax.send(files[index]);
    //     } else {
    //       setuploadError("Upload Failed");
    //     }
    //   })
    //   .catch(() => {
    //     setuploadError("Upload Failed");
    //   });
  };

  const onDrop = useCallback(
    (uploadedFiles) => {
      let acceptedFiles = uploadedFiles.map(
        (file) =>
          new File([file], file.name.replace(/[^a-zA-Z.0-9-_\s+]/g, ""), {
            type: file.type,
            lastModified: file.lastModified,
          }),
      );

      setuploadFiles(false);
      setuploadError(false);
      setPercentage(false);
      setUploadedFile(1);
      setTotalFiles(false);
      input.onChange(false);
      inputRef.current = false;

      if (acceptedFiles && acceptedFiles.length) {
        if (acceptedFiles.every((file) => file && file.size === 0)) {
          setuploadError(`You cannot upload the 0 kb files.`);
          return;
        }
      }

      acceptedFiles = acceptedFiles.filter((file) => file && file.size !== 0);

      if (max && acceptedFiles && max < acceptedFiles.length) {
        errorType
          ? setuploadError(`You cannot upload more than ${max} files`)
          : setuploadError(
            `If you need to upload more than ${max} files, do it in batches of upto ${max}`,
          );
      } else if (upload) {
        inputRef.current = [];
        setTotalFiles(acceptedFiles.length);
        onUpload(acceptedFiles, 0);
      } else {
        setuploadFiles(acceptedFiles);
        setTimeout(() => input.onChange(acceptedFiles), 1000);
      }
    },
    [setuploadFiles, onUpload, setuploadError, upload, input, uploadFiles],
  );

  const onDropRejected = useCallback((error) => {
    setuploadError(error[0].errors[0].message);
  }, []);

  const handleFileDelete = () => {
    setuploadFiles(false);
    input.onChange("");
    if (uploadApi) {
      uploadUserProfile(null);
    }
  };

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple,
    onDropRejected,
  });
  const classes = styles();

  return (
    <>
      {(!input.value && (
        <Grid
          item
          xs={12}
          {...getRootProps({ className: "dropzone" })}
          className={`${classes.container} ${customStyle ? "dropzoneCustom" : "dropzone"
            }`}
        >
          <input {...getInputProps()} />
          <Grid item xs={12} className={classes.container}>
            <Grid container justifyContent="center" direction="column">
              <Grid item>
                <CloudUploadIcon className={customStyle ? "iconWhite" : ""} />
              </Grid>
              {!input.value && !uploadPercentage ? (
                <>
                  <Typography
                    component="span"
                    className={classes.dragLabel}
                    style={customStyle ? { color: "#ffffff" } : {}}
                  >
                    {"Upload Profile Photo"}
                  </Typography>
                </>
              ) : null}
              {uploadPercentage ? (
                <div
                  style={
                    customStyle
                      ? {
                        fontSize: "12px",
                        fontFamily: "var(--font)",
                        color: "#ffffff",
                      }
                      : { fontSize: "12px", fontFamily: "var(--font)" }
                  }
                >
                  {uploadPercentage}% Completed
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      )) ||
        null}
      {(input.value && (
        <Grid item>
          <div
            style={
              customStyle
                ? { position: "relative" }
                : { position: "relative", width: "100px", height: "100px" }
            }
          >
            <img
              src={input.value}
              className={customStyle ? classes.customImage : classes.image}
            />
            <span
              style={{
                position: "absolute",
                top: "0px",
                right: "-10px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={handleFileDelete}
            >
              <CancelIcon className={customStyle ? "closeIconWhite" : ""} />
            </span>
          </div>
        </Grid>
      )) ||
        null}

      {(uploadError || (touched && (error || warning))) &&
        !uploadFiles &&
        !uploadPercentage &&
        uploadedFile ? (
        <Grid container className={classes.container}>
          <Error errorMessage={uploadError || error || warning} />
        </Grid>
      ) : null}
    </>
  );
}

FileUpload.propTypes = {
  children: PropTypes.func,
  accept: PropTypes.string,
};

export default FileUpload;
