import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed",
        bottom: "16px",
        right: "16px",
        height: "50px",
        width: "50px",
        borderRadius: "100%",
        // color: "#FFFFFF",
        // background: theme.palette.accentColor.main,
        [theme.breakpoints.down("sm")]: {
            height: "36px",
        },
    },
    footerDiv: {
        [theme.breakpoints.down("sm")]: {
            height: "420px",
            overflowY: "scroll",
        },
        "@media (max-width: 680px) and (orientation:landscape)": {
            height: "80px",
            overflowY: "scroll",
        },
    },
    text: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
        },
    },
}));

export default useStyles;
