import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    textAlign: "center",
    marginTop: "15px",
    outline: "none",
    position: "relative",
    cursor: "pointer",
  },
  upload: {
    boxShadow: "none",
    width: "130px",
    height: "130px",
    zIndex: "100",
    padding: "5px",
    outline: "none",
    display: "inline-block",
    borderRadius: "100%",
    position: "relative",
  },
  button: {
    fontWeight: "bold",
    borderRadius: "28px",
    textTransform: "none",
    fontFamily: "var(--font)",
    marginTop: "10px",
    outline: "none",
    color: "#ffff",
  },
  image: {
    width: "100px",
    height: "100px",
    display: "inline-block",
    objectFit: "contain",
    borderRadius: "100%",
    padding: "4px",
  },
  customImage: {
    width: "120px",
    height: "120px",
    display: "inline-block",
    borderRadius: "100%",
    objectFit: "contain",
    padding: "4px"
  },
  dragLabel: {
    fontFamily: "var(--font)",
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));

export default useStyles;
